@font-face {
  font-family: 'GeneralSansMedium';
  src: url('fonts/GeneralSans-Medium.woff') format('woff');
}

/* Font for Semibold weight */
@font-face {
  font-family: 'GeneralSansSemibold';
  src: url('fonts/GeneralSans-Semibold.woff') format('woff');
}


h1 {
    
      font-family: 'GeneralSansSemibold', sans-serif;
    font-size: 80px;
    color: #fff;
    margin: 0px;
        line-height: 100%;

    
}

h2 {
    
    font-size: 18px; /* Adjust font size as needed */
    color: #fff;
    font-family: 'GeneralSansMedium', sans-serif;
    font-weight: normal;
    line-height: 140%;
}
    



body {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #010B3B;
       -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.background-image {
    flex: 1;
    position: relative;
    overflow: hidden; /* Ensure the background image doesn't overflow */
        content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../resources/images/positive-lines.svg');
    background-size: contain;
    background-position: bottom center;
    background-repeat: no-repeat;
}
.download-buttons{
    padding-top: 10px;
    display: flex;
}

.apple-store-button {
    padding: 10px 20px; 
    border-radius: 5px; 
    width: 179px; 
    height: 60px; 
  }
  
  
  .google-play-button {
    padding: 10px 20px; 
    border-radius: 5px; 
    width: 179px; 
    height: 60px;
  }

.overlay-gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(to top, rgba(1, 11, 59, 1), rgba(1, 11, 59, 0));
}

.navbar {
    background-color: #010B3B;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    
    
}

.logo img {
    width: auto;
    height: 30px;
}

      .container-side {
        display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      margin-top: 50px;
        flex-direction: row;
        
              padding: 0px 100px;
          
      }

      .left, .right {
        flex: 1;
        
      }

    .left, .right {
      margin: 10px;
    text-align: left;
    }

.nav-button {
    padding: 8px 15px;
    border: 1px solid #11E8C2; /* Added border styling */
    border-radius: 5px;
    cursor: pointer;
    border-radius: 30px;
    background-color: transparent;
    color: #11E8C2;
    width: 151px;
    height: 49px;
    flex-shrink: 0;
    text-align: center;
 font-family: 'GeneralSansMedium', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 123.529% */
    z-index: 1; /* Set a higher z-index value */

}

.nav-button:hover {
    background-color: #11E8C2; /* Background color on hover */
    color: #010B3B; /* Text color on hover */
}

.container {
    text-align: center;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
}

.center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.svg-image {
    width: 280px;
    height: 280px;
    /* Add any additional styles for your SVG if needed */
}

.colour {
    color: #11E8C2;
}

.mobile-button {
    display: none;
        padding: 8px 15px;
    border: 1px solid #11E8C2; /* Added border styling */
    border-radius: 5px;
    cursor: pointer;
    border-radius: 30px;
    background-color: transparent;
    color: #11E8C2;
    width: 151px;
    height: 49px;
    flex-shrink: 0;
    text-align: center;
    font-family: 'GeneralSansMedium', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 123.529% */
        z-index: 1; /* Set a higher z-index value */

}
.mobile-button:hover {
    background-color: #11E8C2; /* Background color on hover */
    color: #010B3B; /* Text color on hover */
}

.side-image {
    
    width: 100%;
}

/* Media Query for Tablet and Smaller Sizes */
@media only screen and (max-width: 768px) {
    
    
    .nav-button {
        display: none; /* Hide the navigation button on smaller screens */
    }

    .center-content {
        margin-top: 20px; /* Adjust spacing as needed */
    }

    .mobile-button {
    padding: 8px 15px;
        margin-top:30px;
    border: 1px solid #11E8C2; /* Added border styling */
    border-radius: 5px;
    cursor: pointer;
    border-radius: 30px;
    background-color: transparent;
    color: #11E8C2;
    width: 151px;
    height: 49px;
    flex-shrink: 0;
    text-align: center;
    font-family: 'General Sans', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 123.529% */
        display: block
}
    
    
h1 {
    
    font-size: 50px;

    
}

h2 {
    
    font-size: 14px; /* Adjust font size as needed */
}
    
    .svg-image {
    width: 240px;
    height: 240px;
    /* Add any additional styles for your SVG if needed */
}
    
          /* Mobile styles */
      .container-side {
        flex-direction: column-reverse;
                padding: 0px 15px;

      }

      .left, .right {
        width: 50%;
             text-align: center;
      }
    
    
.side-image {
    
    max-width: 50%;
    height: auto;
    object-fit: contain;
}
    
    
}